html,
body {
  height: 100vh;
  padding: 0;
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

#__next {
  height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  /*Change text in autofill textbox*/
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #1f2a45 inset !important;
  -webkit-text-fill-color: white !important;
}

*::selection {
  background-color: #86CEFF26;
}

::-webkit-scrollbar-track {
  background-color: #86CEFF1F !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #86CEFF26;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #86CEFF66;
}

.grecaptcha-badge{
  z-index: -1000000000000000;
}
